import 'babel-polyfill';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueBrowserUpdate from 'vue-browserupdate';
import Apptimize from '@apptimize/apptimize-web-sdk';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VuetifyInit from '@/common/vuetifyInit';
import VueBrowserUpdateOptions from '@/common/VueBrowserUpdateOptions';
import Bugsnag from '@/common/Bugsnag';
import { mixPanelService } from '@/services/MixPanelService';

import './bootstrap';
import '@styles/shiftkey.scss';

window.Vue = Vue;

Bugsnag.init();
Vue.use(Vuetify);
Vue.use(VueBrowserUpdate, VueBrowserUpdateOptions);

function isIOSWebView() {
  const userAgent = navigator.userAgent;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent);
  const isWebKit = /WebKit/.test(userAgent);
  const isSafari = /Safari/.test(userAgent);

  // If it's iOS, uses WebKit, and is *not* Safari, it's likely a WebView
  return isIOS && isWebKit && !isSafari;
}

// reCAPTCHA
if (import.meta.env.VITE_RECAPTCHA_ON === 'true') {
  if (!isIOSWebView()) {
    Vue.use(VueReCaptcha, {
      siteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
      loaderOptions: {
        explicitRenderParameters: {
          badge: 'bottomleft',
        },
      },
    });
  }
}
Vue.component('SkLoginRoot', () => import('@/vues/LoginRoot.vue'));

// eslint-disable-next-line no-unused-vars
const app = new Vue({
  vuetify: VuetifyInit,
  el: '#main',
  data: {
    apptimizeInited: false,
  },
});

mixPanelService.app = app;

// Apptimize
Apptimize.setOnApptimizeInitializedCallback(() => {
  app.apptimizeInited = true;
  app.$emit('apptimizeInitialized');
});
Apptimize.setup(import.meta.env.VITE_APPTIMIZE_APP_KEY);
